.drop-in {
  animation: drop-in 1s ease 200ms backwards;
}

.drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translate(0px);
  }
}

@keyframes slideIn {
  to {
    bottom: 0;
    /* move to the bottom of the screen */
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    bottom: 1;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    bottom: 0;
    transform: scale(1);
  }
}

@keyframes zoomChange {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

img {
  /* This section calls the slideInFromLeft animation we defined above */
  //animation: 0.5s ease-out 0s 1 slideInFromLeft;
  animation: 0.5s ease-out 0s 1 zoomChange;

}